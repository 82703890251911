.service-section {
  width: 100%;
  padding: 20px 0;
  .service-section-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 10px 20px;
    width: 90%;
    margin: auto;
    .service-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      padding: 10px 20px;

      .service-icon {
        margin-right: 10px;

        // img {
        //   width: 50px;
        //   height: 50px;
        // }
      }

      .service-info {
        p {
          margin: 0;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .service-section {
    .service-section-inner {
      width: 100%;
      margin: auto;
    }
  }
}
